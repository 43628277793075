import React, { useState, useEffect } from "react";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import { useRouter } from "next/router";
import { withUser, useUser } from "next-firebase-auth";
import OrgContext from "./OrgContext";
import { useQuery, useQueryClient } from "react-query";
import { useRegistration } from './RegistrationContext'; // adjust the import as necessary

const OrgProvider = ({ children }) => {
  console.log('OrgProvider rendered');
  const { registrationInProgress } = useRegistration(); // Access registration state
  const [activeOrg, setActiveOrg] = useState(null);
  const AuthUser = useUser();
  const router = useRouter();
  const db = firebase.firestore();
  const queryClient = useQueryClient();

  // Check if AuthUser is still initializing or AuthUser.id is null
  const isAuthInitializing = !AuthUser || AuthUser.id === null;

  // Log initial AuthUser state for debugging
  useEffect(() => {
    console.log("AuthUser state:", AuthUser);
  }, [AuthUser]);

  // Fetch organizations using react-query
  const fetchOrganizations = async () => {
    const userId = AuthUser?.id;
    if (!userId) {
      console.warn(
        "No AuthUser ID available when trying to fetch organizations."
      );
      return [];
    }

    try {
      const querySnapshot = await db
        .collection("memberships")
        .where("uid", "==", userId)
        .orderBy("created_at")
        .get();

      const orgIds = querySnapshot.docs.map((doc) => doc.data().orgId);
      console.log("Fetched orgIds:", orgIds);

      const orgPromises = orgIds.map((orgId) =>
        db.collection("orgs").doc(orgId).get()
      );
      const orgDocs = await Promise.all(orgPromises);

      let orgs = [];
      for (const doc of orgDocs) {
        let orgDocData = doc.data();
        let parentOrgId =
          orgDocData.parentOrgId || (orgDocData.primaryOrg ? doc.id : null);

        // Get the parent organization data
        let parentDocRef = await db.collection("orgs").doc(parentOrgId).get();
        let parentDocData = parentDocRef.data();

        const subDocRef = await db
          .collection("orgs")
          .doc(parentOrgId)
          .collection("subscriptions")
          .doc(parentDocData.subscriptionId)
          .get();

        // Query websites for this organization
        const websitesSnapshot = await db
          .collection("websites")
          .where("orgId", "==", parentOrgId)
          .get();

        const websites = websitesSnapshot.docs.map((websiteDoc) => {
          return { id: websiteDoc.id, ...websiteDoc.data() };
        });

        // integrtions
        const integrationRef = await db
        .collection("integrations")
        .where("orgId", "==", parentOrgId)
        .get();

      const integrations = integrationRef.docs.map((integrationDoc) => {
        return { id: integrationDoc.id, ...integrationDoc.data() };
      });

        orgs.push({
          orgId: doc.id,
          ...orgDocData,
          websites, // Add the websites array here
          subscription: subDocRef.data(),
          planFeatures: parentDocData.planFeatures,
          integrations,
          parentOrgId:
            orgDocData.parentOrgId || (orgDocData.primaryOrg ? doc.id : null),
        });
      }

      // Log fetched organizations
      console.log("Fetched organizations:", orgs);

      return orgs;
    } catch (error) {
      console.error("Error fetching organizations:", error);
      throw error;
    }
  };

  const {
    data: orgs = [],
    isError,
    error,
  } = useQuery(["orgs", AuthUser?.id], fetchOrganizations, {
    enabled: !isAuthInitializing, // Only run if AuthUser.id is available
  });

  // Handle setting activeOrg after orgs are fetched and AuthUser.id is valid
  useEffect(() => {
    if (!isAuthInitializing && orgs.length > 0 && !activeOrg && AuthUser?.id) {
      console.log(
        "Setting activeOrg from orgs after AuthUser.id is available:",
        orgs
      );
      const firstOrg = orgs[0];
      setActiveOrg(firstOrg);
      //debugger;
      console.log("Active organization set:", firstOrg);
    }
  }, [orgs, activeOrg, isAuthInitializing, AuthUser?.id]);

  // Handle URL-based organization selection once AuthUser.id is available
  useEffect(() => {
    const orgIdFromUrl = router.query.oid;
  
    // Only run the effect if orgIdFromUrl is defined and other necessary values are available
    if (orgIdFromUrl && orgs.length > 0 && AuthUser?.id) {
      const foundOrg = orgs.find((org) => org.orgId === orgIdFromUrl);
      if (foundOrg) {
        setActiveOrg(foundOrg);
        console.log(
          `Active organization set from URL after AuthUser.id is available: ${orgIdFromUrl}`,
          foundOrg
        );
      } else {
        console.error(
          `Organization ID from URL (${orgIdFromUrl}) does not match user memberships.`
        );
      }
    } else if (!orgIdFromUrl) {
      console.log('No organization ID found in the URL, skipping organization setting.');
    }
  }, [router.query.oid, orgs, AuthUser?.id]);

  const updateActiveOrg = (org, register) => {
    // if (!AuthUser?.id) {
    //   console.warn("Attempted to update activeOrg before AuthUser.id was available.");
    //   return;
    // }

    setActiveOrg(org);
    // debugger;
    console.log("Active organization updated:", org);
    const destination = `/org/${org.orgId}/website`;
    console.log(`Destination`, destination);

    if (register) {
      router.push(destination);
    } else {
      router.replace(destination); // avoid adding a new entry to history
    }
  };

  const invalidateOrgs = () => {
    if (AuthUser?.id) {
      queryClient.invalidateQueries(["orgs", AuthUser?.id]);
      console.log("Invalidated orgs cache.");
    } else {
      console.warn(
        "Attempted to invalidate orgs cache before AuthUser.id was available."
      );
    }
  };

  const refetchOrgs = () => {
    queryClient.refetchQueries(["orgs", AuthUser?.id]);
    console.log("Invalidated orgs cache.");
  };

  if (isError) {
    return <div>Error loading organizations: {error.message}</div>;
  }

  // Prevent hanging on login page
  if (router.pathname === "/login" || router.pathname === "/register" || router.path === "/test") {
    // Allow rendering the login page without blocking for auth or org data
    return (<OrgContext.Provider
      value={{
        updateActiveOrg,
        invalidateOrgs,
        refetchOrgs
      }}
    >
      {children}
    </OrgContext.Provider>);
  }

  // Conditionally handle loading based on registrationInProgress
  // if (registrationInProgress || !AuthUser?.id || !activeOrg) {
  //   return <div>Loading......</div>;
  // }

  if (registrationInProgress) {
    return <div>Loading......</div>;
  }

  // if (isAuthInitializing || !orgs.length || !activeOrg) {
  //   return <div>Loading...</div>; // Only render children once AuthUser, orgs, and activeOrg are ready
  // }

  // If the user is not authenticated, render the children so LayoutAppAuthed.js can handle the redirect
  if (!AuthUser?.id) {
    return (
      <OrgContext.Provider
        value={{
          updateActiveOrg,
          invalidateOrgs,
          refetchOrgs
        }}
      >
        {children}
      </OrgContext.Provider>
    );
  }

  if (isAuthInitializing) {
    // Show a loader while Firebase Auth is initializing
    return <div>Authenticating...</div>;
  }
  // Now handle loading orgs and activeOrg

  if (!activeOrg) {
    console.log(`if (!activeOrg) {`, activeOrg);
    return <div>Loading active organization...</div>; // Only block rendering based on org data
  }

  // if (isAuthInitializing || !activeOrg) {
  //   return <div>Loading...</div>;
  // }

  return (
    <OrgContext.Provider
      value={{
        orgs,
        activeOrg,
        updateActiveOrg,
        invalidateOrgs,
      }}
    >
      {children}
    </OrgContext.Provider>
  );
};

export default withUser()(OrgProvider);
